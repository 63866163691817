import logo from "./logo.svg";
import "./App.css";
import styled from "styled-components";

const PageLink = styled.span`
	color: red;
	font-weight: bold;
	font-style: inherit;
	font-family: "Times New Roman", Times, serif;
`;

function App() {
	return (
		<div className="App">
			<header className="App-header">
				<img src={logo} className="App-logo" alt="logo" />
				<p>
					Strona <PageLink>FOPP.AGRO.PL</PageLink> Aktualnie jest w
					trakcie budowy
				</p>
				<a
					className="App-link"
					href="mailto:admin@warocket.pl"
					target="_blank"
					rel="noopener noreferrer"
				>
					admin@warocket.pl
				</a>
			</header>
			<footer>
				<div className="App-footer">
					&copy; Copyright 2023 - Created by Web Agency Rocket
				</div>
			</footer>
		</div>
	);
}

export default App;
